import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'plural'
})
export class PluralPipe implements PipeTransform {

  transform(text: string, plural: boolean): string {
    const parts = text.split('/')
    return parts[plural ? 1 : 0]
  }

}
