import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'

@Component({
  selector: 'spb-wait-dialog',
  templateUrl: './wait-dialog.component.html'
})
export class WaitDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: string) {
  }
}
